import LogoEterna from '../assets/svg/1_section_logo.svg'
import videoEterna from '../assets/video/sectionOne.mp4'

const FirstSection = () => {
    return (
        <div className="" style={{ position: 'relative' }} >

            {/* Desktop */}

            <div className='w-100  d-none d-lg-flex' style={{ position: 'absolute', top: 0, zIndex: -1 }}>
                <video style={{ width: '100%' }} autoPlay muted loop src="https://storage.googleapis.com/artifacts.eternav3.appspot.com/videos/video%20_entrada_seccion_1_B.mp4"></video>
            </div>

            {/* Mobile */}

            <div className='w-100 d-flex d-lg-none' style={{ position: 'absolute', top: 0, zIndex: -1 }}>
                <video style={{ width: '100%' }} autoPlay muted loop src={videoEterna}></video>
            </div>


            <div className='container paddingTitle'>
                <div
                    className='text-white BoxlogoEternaFirst'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <img src={LogoEterna} className='logoEternaFirst' alt='Logo Eterna' />
                </div>

                {/* Desktop */}

                <div
                    className='bg-darkGray text-big text-white mt-3 p-2 SansProLight Subtitle d-none d-lg-flex'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <span className='SansProBold mx-3'>Arquitectura</span> inteligente de sistemas.
                </div>

                 {/* Mobile */}

                <div
                    className='bg-darkGray text-big text-white mt-3 SansProLight Subtitle d-flex d-lg-none flex-column justify-content-start'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className='SansProBold mx-2 d-flex justify-content-start'>Arquitectura</div>
                    <div className='d-flex justify-content-start mx-2' style={{fontSize:'30px'}}>inteligente de sistemas.</div>
                </div>
            </div>
        </div>
    )
}

export default FirstSection