import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
    
  const [GlobalState, setGlobalState] = useState({
    path:'',
    showMenu:false,
    hiddenMenu:false
  });

  return (
    <GlobalContext.Provider
      value={{
        GlobalState,
        setGlobalState
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
