 

const ThirdSection = () => {
    return (
        <div>
            <div className='container mt-5'
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
            >
                <div className='videoBox border'>
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        <div className='w-100 h-100'>
                            <video style={{ borderRadius: '10px' }} width="100%" height="100%" controls autoPlay muted loop>
                                <source src={"https://storage.googleapis.com/artifacts.eternav3.appspot.com/videos/video.mp4"} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdSection