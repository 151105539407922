import React from 'react'

const SixthSection = () => {
    return (
        <div
            // data-aos="fade-left"
            // data-aos-easing="linear"
            // data-aos-duration="1500"
        >

            <div className='container mt-5'>
                <div className=' SansProLight' style={{ fontSize: '35px' }}>Programamos lo que necesites, y todo <br />
                    gracias a nuestro servicio de <span className='SansProBold'>arquitectura <br /> inteligente de sistemas.</span></div>
            </div>
            <div className='container mt-4 border-bottom'>
                <div className='SansProLight d-flex align-items-center' >
                    <div style={{ fontSize: '18px' }}>01</div>
                    <div className='ms-5' style={{ fontSize: '25px' }}>Arquitectura inteligente para Startups.</div>
                </div>
                <div className='SansProLight d-flex align-items-center my-5'>
                    <div style={{ fontSize: '18px' }}>02</div>
                    <div className='ms-5' style={{ fontSize: '25px' }}>Desarrollos web a la medida.</div>
                </div>
                <div className='SansProLight d-flex align-items-center'>
                    <div style={{ fontSize: '18px' }}>03</div>
                    <div className='ms-5' style={{ fontSize: '25px' }}>Diseño de producto pensado en los consumidores.</div>
                </div>
                <div className='SansProLight d-flex align-items-center my-5'>
                    <div style={{ fontSize: '18px' }}>04</div>
                    <div className='ms-5' style={{ fontSize: '25px' }}>Análisis para la creación de sistemas para grandes empresas.</div>
                </div>
                <div className='SansProLight d-flex align-items-center mb-5'>
                    <div style={{ fontSize: '18px' }}>05</div>
                    <div className='ms-5' style={{ fontSize: '25px' }}>MVP para nuevos negocios.</div>
                </div>
            </div>

        </div>
    )
}

export default SixthSection