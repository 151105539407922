import IconFacebook from '../assets/svg/facebook icon.svg'
import IconLinkein from '../assets/svg/linkedin icon.svg'
import LogoEterna from '../assets/svg/1_section_logo.svg'
import { useNavigate } from 'react-router-dom'


const TwelvethSection = () => {
    const navigate = useNavigate()
    const handleToShowTerms = () => {
        navigate("/aviso-de-privacidad")
    }
    return (
        <div className='bg-LightGray'>
            <div className='container'
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
            >
                <div className='text-white pt-5'>
                    <div className='ms-3'>
                        <img src={LogoEterna} width={'150px'} alt='Logo Eterna' />
                    </div>
                </div>

            </div>

            <div className='container mt-4 d-none d-lg-block'>
                <div className='d-flex justify-content-between'>

                    <div className='border-top text-white SansProLight' style={{ width: '380px' }}>
                        <div className='SansProBold my-3' style={{ letterSpacing: '5px' }}>SERVICIOS</div>
                        <div>
                            Buscamos proyectos que logren miles de <br />
                            USUARIOS. Nuestra holding solo se enfoca en <br />
                            proyectos que nos llevan a vivir un mejor <br />
                            futuro.
                        </div>
                    </div>

                    <div className='border-top' style={{ width: '380px' }}>
                        <div className='d-flex my-3'>
                            <div className='cursor-pointer'>
                                <a href="https://www.facebook.com/eternadigital">
                                    <img src={IconFacebook} alt='Icono Facebook' />
                                </a>
                            </div>
                            <div className='cursor-pointer'>
                                <a href="https://www.linkedin.com/company/eterna-digital/">
                                    <img src={IconLinkein} alt='Icono LinkeIn' />
                                </a>
                            </div>
                        </div>
                        <div className='text-white SansProLight'>
                            Somos programadores. Grupo de inversionistas. <br />
                            Líderes en búsqueda de nuevos proyectos.
                        </div>
                    </div>

                    <div className='border-top text-white SansProLight' style={{ width: '380px' }}>
                        <div className='SansProBold my-3' style={{ letterSpacing: '5px' }}>CONTACTO</div>
                        <div>
                            info@eterna.digital
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4 d-md d-lg-none px-4'>
                <div className='d-flex flex-column justify-content-between'>

                    <div className='border-top text-white SansProLight py-3' >
                        <div className='SansProBold my-3' style={{ letterSpacing: '5px' }}>SERVICIOS</div>
                        <div>
                            Buscamos proyectos que logren miles de <br />
                            USUARIOS. Nuestra holding solo se enfoca en <br />
                            proyectos que nos llevan a vivir un mejor <br />
                            futuro.
                        </div>
                    </div>

                    <div className='border-top py-3' >
                        <div className='d-flex my-3'>
                            <div className='cursor-pointer'>
                                <a href="https://www.facebook.com/eternadigital">
                                    <img src={IconFacebook} alt='Icono Facebook' />
                                </a>
                            </div>
                            <div className='cursor-pointer'>
                                <a href="https://www.linkedin.com/company/eterna-digital/">
                                    <img src={IconLinkein} alt='Icono LinkeIn' />
                                </a>
                            </div>
                        </div>
                        <div className='text-white SansProLight'>
                            Somos programadores. Grupo de inversionistas. <br />
                            Líderes en búsqueda de nuevos proyectos.
                        </div>
                    </div>

                    <div className='border-top text-white SansProLight py-3'>
                        <div className='SansProBold my-3' style={{ letterSpacing: '5px' }}>CONTACTO</div>
                        <div>
                            info@eterna.digital
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-4 py-3'>
                <div className='d-flex flex-column justify-content-center align-items-center SansProLight text--gray'>
                    <div className='border-bottom cursor-pointer' onClick={handleToShowTerms} style={{ fontSize: '13px' }}>Terminos y condiciones</div>
                    <div>© 2020 Eterna</div>
                </div>
            </div>
        </div>
    )
}

export default TwelvethSection