export function toTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export function toBottom() {

    let scroll_to_bottom = document.getElementById('footer');
    scroll_to_bottom.scrollIntoView(false);

}
