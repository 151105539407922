import infografia from '../assets/img/infogra_phone.png'
import infografiaMobile from '../assets/svg/infografia mobile.svg'

const FourthSection = () => {
    return (
        <>
            <div>
                <div className='container mt-5'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className=' text-center SansProLight' style={{ fontSize: '45px' }}>Por igual hacemos <span className='SansProBold'>grandes análisis, arquitectura <br /> y desarrollado web inteligente</span> para grandes empresas.</div>
                </div>

            </div>

            <div className='container'>
                <div className='d-none d-lg-block'><img className='w-100' src={infografia} alt='Infografía' /></div>
                <div className='d-md-block d-lg-none'><img className='w-100' src={infografiaMobile} alt='Infografía' /></div>
            </div>
        </>
    )
}

export default FourthSection