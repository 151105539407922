import LogoEternaBlanco from '../assets/svg/07_logo eterna footer.svg'
import fondoNosotros from '../assets/img/fondo nosotros.png'
const TenthSection = () => {
    return (
        <div style={{ position: 'relative' }}>
            <div className='w-100' style={{ position: 'absolute', top: 0, zIndex: -1, backgroundColor: '#3F403F' }} >
                <video autoPlay muted loop className='w-100 d-none d-lg-block' src="https://storage.googleapis.com/artifacts.eternav3.appspot.com/videos/video_phone_ok.mp4"></video>
                <img className='d-md-block d-lg-none paddingTopUs' width={'100%'} src={fondoNosotros} alt="" srcset="" />
            </div>


            <div className='container mt-5 d-none d-lg-block'>
                <div className='pt-5' id='Nosotros'>
                    <img src={LogoEternaBlanco} width={'150px'} alt="Logo Eterna Blanco" />
                </div>
                <div className='pb-5 border-bottom'>
                    <div className='text-white SansProLight' style={{ fontSize: '55px' }}>De nosotros para ustedes.</div>
                </div>
                {/* desktop */}
                <div className='d-none d-lg-flex justify-content-center align-items-center SansProLight border' style={{ height: '600px', width: '70%' }}>
                    <div>
                        <div className='text-white SansProSemibold' style={{ fontSize: '30px', letterSpacing: '2px' }}>Sobre nosotros</div>
                        <div className='text-white SansProLight'>
                            Nacimos con sed de ver mejorar al mundo, y sabemos que <br />
                            la tecnología tiene grandes capacidades de hacer cosas <br />
                            sobresalientes.
                        </div>
                        <div className='text-white mt-3 SansProLight'>
                            Es por esto que nos pusimos la misión de ser reales, <br />
                            honestos y sobre todo soñadores. Y gracias a esto es que <br />
                            vemos a cada uno de nuestros clientes como un granito de <br />
                            arena que ayudará a construir un mejor futuro.
                        </div>
                    </div>
                </div>
                
            </div>

            <div className='container mt-5 d-md-block d-lg-none'>
                <div className='pt-5' id='Nosotros'>
                    <img src={LogoEternaBlanco} width={'150px'} alt="Logo Eterna Blanco" />
                </div>
                <div className=''>
                    <div className='text-white SansProLight' style={{ fontSize: '55px' }}>De nosotros para ustedes.</div>
                </div>
                {/* mobile */}
                <div className='d-md-flex d-lg-none justify-content-center align-items-center SansProLight' style={{ height: '600px', width: '70%' }}>
                    <div>
                        <div className='text-white SansProSemibold' style={{ fontSize: '30px', letterSpacing: '2px' }}>Sobre nosotros</div>
                        <div className='text-white SansProLight' style={{textAlign:'justify'}}>
                            Nacimos con sed de ver mejorar al mundo, y sabemos que 
                            la tecnología tiene grandes capacidades de hacer cosas 
                            sobresalientes.
                        </div>
                        <div className='text-white mt-3 SansProLight' style={{textAlign:'justify'}}>
                            Es por esto que nos pusimos la misión de ser reales,
                            honestos y sobre todo soñadores. Y gracias a esto es que 
                            vemos a cada uno de nuestros clientes como un granito de 
                            arena que ayudará a construir un mejor futuro.
                        </div>
                    </div>
                </div>
                {/* <div className=' d-flex justify-content-center align-items-center SansProLight' style={{ width: '70%' }}>
                    <div>
                        <div className='text-white SansProSemibold' style={{ fontSize: '30px', letterSpacing: '2px' }}>Sobre nosotros</div>
                        <div className='text-white SansProLight'>
                            Nacimos con sed de ver mejorar al mundo, y sabemos que <br />
                            la tecnología tiene grandes capacidades de hacer cosas <br />
                            sobresalientes.
                        </div>
                        <div className='text-white mt-3 SansProLight'>
                            Es por esto que nos pusimos la misión de ser reales, <br />
                            honestos y sobre todo soñadores. Y gracias a esto es que <br />
                            vemos a cada uno de nuestros clientes como un granito de <br />
                            arena que ayudará a construir un mejor futuro.
                        </div>
                    </div>
                </div> */}
            </div>


        </div>
    )
}
export default TenthSection
