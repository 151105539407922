import { BsArrowUp } from 'react-icons/bs'
import { toTop } from '../utils/animationFunctions'
import { useContext } from 'react'
import { GlobalContext } from '../context/global_context'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Menu = () => {


  let url = window.location.href
  const navigate = useNavigate()

  const handleGoToTop = () => {
    toTop()
  }
  const { GlobalState, setGlobalState } = useContext(GlobalContext)


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      console.log(url)
      if (url.includes('aviso-de-privacidad')) {
        setGlobalState({ ...GlobalState, showMenu: true })
      } else {
        setGlobalState({ ...GlobalState, showMenu: false })
      }

    }

    return () => {
      isMounted = false
    }
  }, [url, window.location])


  return (
    <>
      <div className='MenuFixedLeft p-3 text-lightGray SansProRegular'>
        <div className="d-flex justify-content-end border-bottom pb-2 text" onClick={() => {
          setGlobalState({ ...GlobalState, hiddenMenu: false })
        }}> // MENÚ</div>

        {GlobalState.showMenu ?

          <div className='d-flex justify-content-end p-2 cursor-pointer' onClick={() => {
            navigate("/")
          }}>Regresar</div>
          :
          <>

            <div className="d-flex justify-content-end p-2 cursor-pointer " onClick={() => {
              document.getElementById('Nosotros').scrollIntoView({
                behavior: 'smooth'
              });
              setGlobalState({ ...GlobalState, hiddenMenu: false })
            }}>NOSOTROS</div>
            <div className="d-flex justify-content-end p-2 cursor-pointer" onClick={() => {

              document.getElementById('Metodologias').scrollIntoView({
                behavior: 'smooth'
              });
              setGlobalState({ ...GlobalState, hiddenMenu: false })
            }}>METODOLOGÍAS</div>
            <div className="d-flex justify-content-end p-2 cursor-pointer" onClick={() => {

              document.getElementById('Proyectos').scrollIntoView({
                behavior: 'smooth'
              });
              setGlobalState({ ...GlobalState, hiddenMenu: false })
            }}>PROYECTOS</div>
            <div className="d-flex justify-content-end border-bottom p-2 cursor-pointer" onClick={() => {
              document.getElementById('Contacto').scrollIntoView({
                behavior: 'smooth'
              });
              setGlobalState({ ...GlobalState, hiddenMenu: false })
            }}>CONTACTO</div>
          </>
        }
      </div>
      <div className="cta-ToTop cursor-pointer" style={{ zIndex: 2 }} onClick={handleGoToTop}>
        <div className='ArrowIcon'>
          <BsArrowUp />
        </div>
      </div>
    </>
  )
}

export default Menu