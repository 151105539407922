import FirstSection from '../components/firstSection'
import SecondSection from '../components/secondSection'
import ThirdSection from '../components/thirdSection'
import FourthSection from '../components/fourthSection'
import Fifthsection from '../components/fifthsection'
import SixthSection from '../components/sixthSection'
import SeventhSection from '../components/seventhSection'
import EighthSection from '../components/eighthSection'
import NinthSection from '../components/ninthSection'
import TenthSection from '../components/tenthSection'
import EleventhSection from '../components/eleventhSection'
import TwelvethSection from '../components/twelvethSection'
// import PopUp from '../components/ui/popUp';

function Home() {
    
    return (
        <>
            {/* FIRST SECTION */}
            {/* {state.popUp ?
                <PopUp onclose={() => {
                    setstate({ ...state, popUp: true })
                }} />
                :
                null
            } */}

            {/* FIRST SECTION */}

            <FirstSection />

            {/* SECOND SECTION */}

            <SecondSection />

            {/* THIRD SECTION */}

            <ThirdSection />

            {/* QUARTER SECTION */}

            <FourthSection />

            {/* FIFTH SECTION */}

            <Fifthsection />

            {/* SIXTH SECTION */}

            <SixthSection />

            {/* SEVENTH SECTION */}

            <SeventhSection />

            {/* EIGHTH SECTION */}

            <EighthSection />

            {/* NINTH SECTION */}

            <NinthSection />

            {/* TENTH SECTION */}

            <TenthSection />

            {/* ELEVENTH SECTION */}

            <EleventhSection />

            {/* TWELVETH SECTION */}

            <TwelvethSection />
        </>
    )
}

export default Home



