
const EleventhSection = () => {
    return (
        <>
            <div>
                <div>
                    <div className='container mt-5'
                        data-aos="fade-right"
                        data-aos-easing="linear"
                        data-aos-duration="1500"
                    >
                        <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '0' }}>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <div className='bg-bubble '></div>
                                <div className='text-center' style={{ fontSize: '60px', marginTop: '-300px' }}> <span className='SansProLight'>De soñadores a</span> soñadores</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className=''
            // data-aos="fade-left"
            // data-aos-easing="linear"
            // data-aos-duration="1500"
            >
                <div className='bg-footer'>
                    <div className='d-flex align-items-center h-100'>
                        <div className='container'>
                            <div className='SansProLight' style={{ fontSize: '40px' }}>Nuestros Valores</div>
                            <div className="d-none d-lg-block SansProLight">
                                Gracias a nuestra experiencia creando nuevas tecnologías vimos <br />
                                que es indispensable contar con una alianza estratégica entra a <br />
                                idea, el desarrollo, y los equipos que logran levantar un proyecto.
                            </div>
                            <div className='SansProLight d-md-block d-lg-none' style={{textAlign:'justify'}}>
                                Gracias a nuestra experiencia creando nuevas tecnologías vimos 
                                que es indispensable contar con una alianza estratégica entra a 
                                idea, el desarrollo, y los equipos que logran levantar un proyecto.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EleventhSection