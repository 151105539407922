import { toTop } from "../utils/animationFunctions"

function NoticePrivacyView() {
    toTop()
  

    return (
        <>
            <div className="bg-LightGray" >
                <div className="container text-white SansProLight pt-5">
                    <div className="SansProBold border-bottom" style={{ fontSize: '60px' }}>Aviso de Privacidad</div>
                    <div className="SansProSemibold my-2" style={{ fontSize: '45px' }}>Condiciones de uso de la web</div>
                    <div className="SansProSemibold my-4">Política de Privacidad y Protección de Datos en Posesión de Particulares</div>
                    <div className="">
                        {`
                        A efecto de garantizar el principio de información, la privacidad y el derecho a la autodeterminación informativa de los titulares, ETERNA DIGITAL S.A. DE C.V., con domicilio en Ciudad de México, (en adelante “ETERNA”), conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”), y en su carácter de responsable sobre el tratamiento de datos personales, informa a los titulares (en lo sucesivo “Usuarios”) de su página web (en lo sucesivo el “Portal”) acerca de la Política de Privacidad y Protección de Datos Personales que aplicará para el tratamiento legítimo, controlado e informado de los datos personales que los Usuarios faciliten voluntariamente.
                        ETERNA recaba de los candidatos o postulantes para ocupar puestos en ETERNA, datos personales que pertenecen a las siguientes categorías: (i) datos de identificación; (ii) antecedentes laborales; (iii) datos de contacto; (iv) datos académicos; (v) datos patrimoniales o financieros; (vi) datos bancarios; (vii) datos biométricos; (viii) datos personales de terceros y (ix) datos personales de menores de edad.
                        `}
                    </div>
                    <div className="mt-4">
                        {`
                        ETERNA también podrá recabar la dirección de IP de los Usuarios para administrar el Portal. Una dirección de IP es un número que se le asigna a una computadora cuando usa Internet. Su dirección de IP también es utilizada para ayudar a identificarle dentro de una sesión particular y para recolectar información demográfica general. La información puede incluir la URL de la que provienen (estén o no en nuestro Sitio Web), a qué URL acceden seguidamente (estén o no en nuestro Sitio Web), qué navegador están usando, así como también las páginas visitadas, las búsquedas realizadas, las publicaciones, compras o ventas.
                        En términos de la Ley, a acceder al Portal y proporcionar sus datos personales a ETERNA, a excepción de que manifieste lo contrario, usted consiente tácitamente el tratamiento de los mismos de conformidad con lo establecido en este Aviso de Privacidad.
                        En cumplimiento a lo dispuesto en los artículos 15, 16 y demás aplicables de la Ley, ETERNA pone a su disposición el presente Aviso de Privacidad, el cual estará en todo momento disponible en nuestra página de Internet: https://eterna.digital/
                        `}
                    </div>
                    <div className="SansProSemibold my-4">Política de Privacidad y Protección de Datos en Posesión de Particulares</div>
                    <div>
                        El Usuario, al proporcionar a ETERNA sus datos personales a través de los formularios electrónicos del Portal, consiente expresamente este Aviso de Privacidad y permite a ETERNA tratar esos datos conforme los términos de este Aviso de Privacidad, Política de Privacidad y Protección de Datos en Posesión de Particulares.
                    </div>
                    <div className="mt-2">
                        {`
                        El Usuario autoriza a ETERNA a proporcionar información sin su consentimiento en los siguientes casos: (i) cuando esté previsto en una ley; (ii) los datos figuren en fuentes de acceso público; (iii) los datos personales se sometan a un procedimiento previo de disociación; (iv) tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable; (v) exista una situación de emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes; o (vi) se dicte resolución de autoridad competente; (vii) cuando la transferencia esté prevista en una ley o tratado en los que México sea parte; (viii) cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia; (ix) cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; (x) cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular; y (xi) cuando lo soliciten sus socios comerciales, técnicos o estratégicos, con la finalidad de ofrecer mejores contenidos y servicios al Usuario.
                        `}
                    </div>
                    <div className="SansProSemibold my-4">Registro</div>
                    <div>
                        Antes de registrarse en https://eterna.digital/, el Usuario deberá leer este Aviso de Privacidad, Política de Privacidad y Protección de Datos en Posesión de Particulares y aceptarla marcando la casilla en el formulario de registro de ETERNA. Al rellenar el formulario deberán proporcionar los siguientes datos reales: nombre completo, domicilio, correo electrónico, teléfono, contacto de emergencia, información de pago (tales como información de tarjetas de crédito o débito, información de cuenta de débito directo), nombre de usuario y contraseña, credenciales de cuentas de terceros (por ejemplo, sus credenciales de inicio de sesión de Facebook) y campos no obligatorios de cumpleaños, dirección, género, sitios personales y clases favoritas.
                    </div>
                    <div className="mt-4">
                        El Usuario se compromete a mantener los datos proporcionados a ETERNA debidamente actualizados. El Usuario será responsable de los daños y perjuicios que ETERNA o terceros pudieran sufrir como consecuencia de la falta de veracidad, inexactitud, falta de vigencia y autenticidad de los datos facilitados.
                    </div>
                    <div className="SansProSemibold my-4">Ley Federal de Protección de Datos Personales en Posesión de los Particulares</div>
                    <div>
                        ETERNA manifiesta su compromiso de cumplir con la legislación vigente en cada momento en materia de protección de datos, concretamente con la Ley y demás legislación aplicable, tal y como sea modificada en cada momento.
                    </div>
                    <div className="mt-4">
                        Al registrarse en la Plataforma, el Usuario manifiesta su consentimiento expreso para el tratamiento de sus datos de carácter personal que formarán parte de una base de datos, titularidad de ETERNA con la finalidad de gestionar la relación con el Usuario, así como para mantenerle informado sobre las novedades, productos, ofertas y servicios de ETERNA por cualquier canal, incluido el electrónico.
                    </div>
                    <div className="SansProSemibold my-4">Utilización de los datos</div>
                    <div>
                        Al registrarse en la Plataforma, el Usuario autoriza expresamente a ETERNA a utilizar sus datos personales para análisis estadísticos internos o para cualquier otra función de investigación que ETERNA pudiese llevar a cabo con el fin de seguir mejorando sus productos, servicios y el Portal. Lo anterior con la única finalidad de realizar de manera adecuada las actividades y prestación de los servicios, incluyendo, personalizar, brindar y mejorar nuestros servicios, establecer una cuenta de usuario y un perfil, contactarlo lo anterior para poder satisfacer sus solicitudes de ciertos productos y servicios, y para analizar cómo usted utiliza los servicios. ETERNA no almacenará datos bancarios.
                    </div>
                    <div className="mt-4">
                        Además, el Usuario permite expresamente a ETERNA a recopilar información para la realización de seguimiento de tráfico, con intención de identificar grupos y perfiles de usuarios, así como para fines de orientación publicitaria.
                    </div>
                    <div className="mt-4">
                        Al aceptar la presente Política de Privacidad, el Usuario cede a ETERNA la titularidad sobre la información relativa a la utilización del producto por parte del Usuario, y autoriza a ETERNA a hacer uso de dicha información en la manera que estime conveniente.
                    </div>
                    <div className="SansProSemibold my-4">Recibo y envío de Correos Electrónicos</div>
                    <div>
                        Al registrarse en ETERNA con el correo electrónico personal, el Usuario acepta recibir correos electrónicos, SMS, notificaciones push, mensajes en redes sociales y notificaciones en medios conocidos y por conocerse. El Usuario consiente recibir tanto las notificaciones obligatorias para el correcto funcionamiento del Portal, como las relativas a promociones e información de interés.
                    </div>
                    <div className="mt-4">
                        El Usuario puede optar por dejar de recibir los correos electrónicos y notificaciones promocionales en cualquier momento haciendo clic en el enlace para darse de baja que se encuentra en todos los correos electrónicos. No obstante, en ningún caso se podrá optar por dejar de recibir los correos electrónicos o notificaciones necesarios para el correcto funcionamiento del Portal.
                    </div>
                    <div className="mt-4">
                        Asimismo, si elige descargar nuestros informes o documentos; o suscribirte a nuestro boletín de noticias, eventos y alertas; o enviar una consulta, ETERNA puede solicitarle que complete un formulario con información tal como: su nombre, dirección de correo electrónico, cargo y compañía. A partir de este punto, ETERNA podrá relacionar la información enumerada anteriormente sobre su dispositivo, su navegador y la forma en que navega por el contenido de nuestro sitio web directamente a usted.
                    </div>
                    <div className="SansProSemibold my-4">Medidas y Niveles de Seguridad</div>
                    <div>
                        ETERNA, ha adoptado las medidas necesarias para mantener el nivel de seguridad requerido a la naturaleza de los datos personales tratados y las circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible, su alteración, pérdida, tratamiento o acceso no autorizado.
                    </div>
                    <div className="mt-4">
                        ETERNA cuenta con las medidas de seguridad, administrativas, técnicas y físicas necesarias para salvaguardar la privacidad y confidencialidad de los datos personales, así como para cumplir con los principios de protección de datos personales previstos en la Ley.
                    </div>
                    <div className="mt-4">
                        No obstante, es importante tener en cuenta que la seguridad perfecta no existe en Internet. Por ello, ETERNA no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas. De igual forma, ETERNA no se hace responsable por la indebida utilización de la información obtenida por esos u otros medios
                    </div>
                    <div className="mt-4">
                        ETERNA no asume ninguna obligación de mantener confidencial cualquier otra información que el Usuario proporcione a través de boletines o chats, así como la información que obtenga a través de cookies, o cualquier otra información que no haya sido directamente entregada a ETERNA.
                    </div>
                    <div className="mt-4">
                        Al momento de contratar el servicio a través del Portal, se pedirán datos bancarios para los cuales nos comprometemos a ofrecer seguridad y confidencialidad de los datos que nos proporcionan. Para ello, contamos con un servidor seguro y confidencial, de tal manera que la información que nos envían, se transmita encriptada para garantizar su protección en nuestro sitio.
                    </div>
                    <div className="mt-4">
                        A efecto de estar en posibilidad de dar cumplimiento a las finalidades previstas en este Aviso de Privacidad, cuando a juicio de ETERNA sea necesario, se podrá efectuar la transferencia de ciertos datos personales de los usuarios a favor de terceros, comprometiéndose en todo caso ETERNA a velar por el cumplimiento de los principios de protección de datos personales establecidos en la Ley y a tomar las medidas necesarias y suficientes para garantizar que tanto la Ley como este Aviso de Privacidad sean respetados en todo momento por él o los terceros a los que ETERNA transfiera los datos personales.
                    </div>
                    <div className="mt-4">
                        En este sentido, ETERNA podrá realizar la transferencia de sus datos personales, entre otros, a sus empresas subsidiarias y afiliadas, autoridades y organismos auto regulatorios y a terceros prestadores de los servicios necesarios para su debida operación y para el cumplimiento de las finalidades previstas en el presente Aviso de Privacidad.
                    </div>
                    <div className="mt-4">
                        Salvo que usted manifieste su oposición, se entenderá que otorga su consentimiento a ETERNA para transferir a terceros sus datos personales, siempre que ello sea necesario para el cumplimiento de las finalidades establecidas en el presente Aviso de Privacidad. Lo anterior, en el entendido que el tercero receptor asumirá las mismas responsabilidades y/u obligaciones de ETERNA derivadas del presente Aviso de Privacidad, debiendo respetar el mismo en todo momento.
                    </div>
                    <div className="SansProSemibold my-4">Ejercicio de Derechos</div>
                    <div>
                        De conformidad con lo dispuesto en la Ley, a partir del 06 de enero de 2018, el Usuario tendrá el derecho, en todo momento, de acceder a sus datos personales en poder de ETERNA, rectificarlos en caso de que éstos sean inexactos o estén incompletos, instruirnos a cancelarlos cuando considere que son excesivos o innecesarios para las finalidades que justificaron su obtención o haya finalizado la relación contractual con ETERNA, así como a oponerse por causa legítima al tratamiento de sus datos personales.
                    </div>
                    <div>
                        {`
                        Asimismo, el Usuario podrá ejercer los derechos de acceso, rectificación, cancelación y oposición (los “Derechos ARCO”) antes referidos, en cumplimiento de lo establecido en la Ley.
                        `}
                    </div>
                    <div className="mt-4">
                        El ejercicio de estos derechos deberá realizarse por escrito dirigido a ETERNA y deberá incluir lo siguiente:
                    </div>
                    <div className="mt-4">
                        <ul>
                            <li> Nombre completo y domicilio o cualquier otro medio para comunicar la respuesta;</li>
                            <li> Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos de acceso, rectificación, cancelación u oposición a los mismos;</li>
                            <li>Firma del titular de los derechos en cuestión;</li>
                            <li>Adjuntar copia de documentación que acredite la identidad del titular de los derechos y/o de su representante legal; y</li>
                            <li>Tratándose de solicitudes de rectificación, deberá indicar además las modificaciones a realizarse y aportar la documentación que sustente su petición, en caso de que ésta sea procedente.</li>
                            <li>La solicitud debe enviarse a ETERNA al correo electrónico: info@eterna.digital indicando en el asunto del email “Protección de datos”. ETERNA comunicará al Usuario, en un plazo de 20 (veinte) días hábiles desde la fecha en que se recibió la solicitud, la determinación adoptada. Una vez que haya sido enviada la contestación en el plazo señalado, ETERNA tendrá 15 (quince) días hábiles para ejecutar el Acceso, Rectificación, Cancelación u Oposición de los datos personales conforme a la solicitud.</li>
                            <li>Asimismo, tiene derecho a solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta; que ETERNA la elimine de sus registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la Ley; así como a oponerse al uso de sus datos personales para fines específicos.</li>
                        </ul>
                    </div>
                    <div className="SansProSemibold my-4">Limitación del Uso y Divulgación de los Datos Personales</div>
                    <div>En adición a los derechos antes mencionados, usted tendrá, en todo momento, el derecho de limitar el uso o divulgación de sus datos personales, así como el derecho de dejar de recibir mensajes promocionales o publicidad.</div>
                    <div className="mt-4">A efecto de ejercer dicho derecho, se deberá enviar la solicitud respectiva a nuestra Unidad Especializada de Atención al Usuario a la dirección de correo electrónico y con los requisitos señalados para el ejercicio de los Derechos ARCO, cuando sean aplicables.</div>
                    <div className="SansProSemibold my-4">Revocación del Consentimiento para el Tratamiento de Datos Personales</div>
                    <div>
                        Asimismo, tendrá, en todo momento, el derecho de revocar el consentimiento otorgado a ETERNA para el tratamiento de sus datos personales en términos de lo establecido en este Aviso de Privacidad, sin que se le atribuyan efectos retroactivos a la revocación.
                    </div>
                    <div className="mt-4">
                        A efecto de ejercer dicho derecho, se deberá enviar la solicitud respectiva a nuestra Unidad Especializada de Atención al Usuario a la dirección de correo electrónico y con los requisitos señalados para el ejercicio de los Derechos ARCO, cuando sean aplicables.
                    </div>
                    <div className="SansProSemibold my-4">Modificaciones al Aviso de Privacidad</div>
                    <div>
                        ETERNA se reserva el derecho de efectuar, en cualquier momento, modificaciones o actualizaciones a este Aviso de Privacidad ya sea como consecuencia de reformas legislativas, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios y/o productos, prácticas del mercado u otros. Dichas modificaciones se tendrán por aceptadas en caso de que usted no exprese su inconformidad con las mismas dentro de un plazo de 3 (tres) días contados a partir de que las mismas se hayan efectuado.
                    </div>
                    <div className="mt-4">
                        Las modificaciones o actualizaciones serán incorporadas al presente Aviso de Privacidad y estarán disponibles en nuestra página de Internet, o bien, se le harán llegar al último correo electrónico que nos haya proporcionado.
                    </div>
                    <div className="SansProSemibold my-4">Quejas y Denuncias</div>
                    <div>
                        En caso de que usted considere que el derecho a la protección de sus datos personales ha sido vulnerado por alguna conducta de nuestros empleados o por la actuación de ETERNA y, por tanto, presuma que existe alguna violación a las disposiciones previstas en la Ley, podrá interponer la queja correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales. Para mayor información visite la página de Internet www.inai.org.mx
                    </div>
                    <div className="SansProSemibold my-4">Fecha última de actualización 2022.</div>
                    <div>
                        Al no oponerme a la presente, autorizo a ETERNA bel tratamiento y uso de mis datos personales en términos del presente Aviso de Privacidad y, en caso de ser aplicable, declaro, bajo protesta de decir verdad, que cuento con las facultades necesarias para ejercer legalmente la patria potestad, tutoría y/o representación legal del menor, cuyos datos personales estoy divulgando y, además, cuento con el consentimiento para proporcionar los datos personales de los terceros señalados como referencias personales y/o laborales.
                    </div>
                    <div className="py-4">
                        De conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (a) reconozco que he leído y entiendo los alcances del Aviso de Privacidad de ETERNA y (b) autorizo de forma expresa a ETERNA a recabar, tratar y transferir mis datos personales para los fines establecidos en y de conformidad con lo previsto en el Aviso de Privacidad, según el mismo sea modificado de tiempo en tiempo.
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoticePrivacyView



