import { useContext } from 'react'
import LogoEternaBlanco from '../assets/svg/07_logo eterna footer.svg'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GlobalContext } from '../context/global_context'
import Menu from './menu'

const Navbar = () => {
    
    const {GlobalState, setGlobalState} = useContext(GlobalContext)
    const handleShowMenu = () => {
        setGlobalState({...GlobalState, hiddenMenu:true}) 
    }


    return (
        <>
            {GlobalState.hiddenMenu ?
                <Menu/> 
               :
               null 
            }
            <div className='Logo'>
                <div className="">
                    <img className='logoEterna' src={LogoEternaBlanco} />
                </div>
            </div>
            <div className='Hamburguer' onClick={handleShowMenu}>
                <GiHamburgerMenu />
            </div>
            {/* <div className='textRightFloat'>
                <div>// ARQUITECTURA INTELIGENTE DE SISTEMAS.</div>
            </div> */}
        </>
    )
}

export default Navbar