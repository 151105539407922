import Arrow from '../assets/svg/02_icon_arrow.svg'
import Bubble from '../assets/img/03_icon_bubblea.png'
import Elipses from '../assets/svg/04_icon_elipses.svg'
import IconMetodologia1 from '../assets/svg/05_icon_metodologia.svg'
import IconMetodologia2 from '../assets/svg/06_icon_metodologia.svg'
import IconMetodologia3 from '../assets/svg/07_icon_metodologia.svg'


const SecondSection = () => {
    return (
        <div className=' marginTopSecondSection'>
            <div className='container mt-5'
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1500">
                <div className='d-flex align-items-center justify-content-between border-bottom'>
                    <div className='SansProLight titleHacemos'>Hacemos desarrollos <br /> web a la medida</div>
                    <div className=''>
                        <img src={Arrow} className='ArrowImage' alt='flecha' />
                    </div>
                    <div>
                        <img src={Bubble} className='BubbleImage' alt='Burbuja' />
                    </div>

                </div>
                <div className='mt-3 SansProLight' style={{}}>Seguimos ayudando a diferentes emprendedores <br /> a hacer realidad sus sueños</div>

            </div>
            <div
                // data-aos="fade-left"
                // data-aos-easing="linear"
                // data-aos-duration="1500"
                style={{ overflowY: 'auto', overflow: 'hidden' }}
            >
                <div
                    className='d-flex SansProLight animationLeftRight mt-3'
                    style={{ fontSize: '45px', letterSpacing: '10px' }}

                >
                    <div className=''  >{'// Despachos '}</div>
                    <div className='mx-3'>{'// Farmaceúticas'}</div>
                    <div  >{'// Servicios al cliente y soporte técnico'}</div>
                    <div className='mx-3'  >{'// Comercio electrónico y venta minorista en línea'}</div>
                    <div  >{'// Turismo y hospitalidad'}</div>
                    <div className='mx-3' >{'// Salud y bienestar'}</div>
                    <div  >{'// Educación y e-learning'}</div>
                    <div className='mx-3'>{'// Banca y servicios financieros'}</div>
                    <div  >{'// Telecomunicaciones'}</div>
                    <div className='mx-3'>{'// Seguros'}</div>
                    <div  >{'// Automoción y transporte'}</div>
                    <div className='mx-3'>{'// Servicios profesionales y consultoría'}</div>
                </div>
            </div>


            <div
                className='container mt-5 border-top pt-5'
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1500"
            >
                <div className='d-flex align-items-center justify-content-between '>
                    <div className='' id="Metodologias">
                        <img src={Arrow} className='ArrowImage2' alt='Flecha' />
                    </div>
                    <div className='me-4'>
                        <img src={Bubble} className='bubleImage2' alt='Burbuja' />
                    </div>
                    <div className='SansProLight' style={{ marginLeft: '0px', letterSpacing: '10px' }}>
                        <div className='metodologiasFont'>metodologías</div>
                    </div>

                    <div>
                        <img src={Elipses} className='elipseImage' alt='Elipses' />
                    </div>

                </div>

            </div>

            <div
                className='container mt-5 SansProLight d-lg-flex justify-content-between'
                data-aos="zoom-in"
                data-aos-easing="ease-in"
                data-aos-duration="1500"
            >
                <div>
                    <div className='border-bottom'>
                        01
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img style={{ stroke: 'red' }} src={IconMetodologia1} width={'20px'} alt='Icono Metodología' />
                        </div>
                        <div className='mt-3'>
                            Nuestra metodología ayuda a las nuevas <br /> empresas a conquistar a sus inversionistas.
                        </div>
                    </div>
                </div>
                <div>
                    <div className='border-bottom'>
                        02
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img style={{ stroke: 'red' }} src={IconMetodologia2} width={'20px'} alt='Icono Metología 2' />
                        </div>
                        <div className='mt-3'>
                            Las soluciones que presentamos han ayudado a <br /> varias Startups a levantar capital semilla.
                        </div>
                    </div>
                </div>
                <div>
                    <div className='border-bottom'>
                        03
                    </div>
                    <div className='mt-3'>
                        <div>
                            <img style={{ stroke: 'red' }} src={IconMetodologia3} width={'20px'} alt='icono metodología 3' />
                        </div>
                        <div className='mt-3'>
                            El trabajo desarrollado ha causado que nuevos <br /> empresarios nos hagan socios de sus ideas.
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default SecondSection