import Bubble from '../assets/img/03_icon_bubblea.png'
import Arrow from '../assets/svg/02_icon_arrow.svg'

const NinthSection = () => {
    return (
        <div>
            <div>
                <div className='container mt-5 px-3'
                    // data-aos="fade-left"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                >
                    <div className='SansProLight cta-contacto' onClick={() => {

                        /// MAILTO infor@eterna -----
                        window.location.href = "mailto:info@eterna.digital";

                    }} id="Contacto">{'// CONTACTO'}</div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='SansProLight' style={{ fontSize: '60px' }}>Hagamos realidad tu <br /> siguiente proyecto</div>
                        <div className='mx-5 d-none d-lg-block'>
                            <img src={Arrow} width={'200px'} alt='Flecha' />
                        </div>
                        <div className='d-none d-lg-none'>
                            <img src={Bubble} width={'100px'} alt='Burbuja' />
                        </div>

                    </div>
                </div>
                <div className='container my-5'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className='d-none d-lg-flex justify-content-between SansProLight'>
                        <div className='d-flex align-items-center'>
                            <div className=''>Conoce nuestro planes para nuevos negocios</div>
                        </div>
                        <div className='d-flex align-items-center borderLeftRight-gray'>
                            <div className=' SansProBold'>Info@eterna.digital</div>
                        </div>
                        <div className='' style={{ fontSize: '12px' }}>
                            <div>Dirección</div>
                            <div>Paseo de la Av.Paseo de la Reforma 393 int 401.</div>
                            <div>Colonia Cuauhtemoc.</div>
                            <div>CP:06500</div>
                        </div>
                    </div>


                    <div className='d-md-flex d-lg-none flex-column justify-content-between SansProLight ps-5' style={{ fontSize: '20px' }}>
                        <div className='d-flex align-items-center '>
                            <div className='py-4 border-bottom'>Conoce nuestro planes para nuevos negocios</div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='py-4 border-bottom SansProBold'>Info@eterna.digital</div>
                        </div>
                        <div className='py-4 border-bottom' >
                            <div>Dirección</div>
                            <div>Paseo de la Av.Paseo de la Reforma 393 int 401.</div>
                            <div>Colonia Cuauhtemoc.</div>
                            <div>CP:06500</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default NinthSection