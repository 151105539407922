import Logotipos from '../assets/img/logotipos.png'
import LogotiposMobile from '../assets/img/logos clientes mobile.png'

const SeventhSection = () => {
    return (
        <div>
            <div className='container mt-5'
                data-aos="zoom-in"
                data-aos-easing="ease-in"
                data-aos-duration="1500"
            >
                <div className='d-none d-lg-flex justify-content-center'>
                    <img src={Logotipos} width={'70%'} alt='Logotipos' />
                </div>
                <div className='d-md-flex d-lg-none justify-content-center'>
                    <img src={LogotiposMobile} width={'100%'} alt='Logotipos' />
                </div>
            </div>
        </div>
    )
}

export default SeventhSection