import Arrow from '../assets/svg/02_icon_arrow.svg'
import Bubble2 from '../assets/img/03_icon_bubbleb.png'
import ImgReclutachat from '../assets/img/3_proyectos_reclutachat.png'
import LogoReclutachat from '../assets/img/reclutachat logo.svg'
import ImgTlegal from '../assets/img/4_proyectos_tlegal.png'
import LogoTlegal from '../assets/img/tlegal logo.svg'
import ImgNetbee from '../assets/img/5_proyectos_netbee.png'
import LogoNetbee from '../assets/img/netbee logo.svg'
import ImgCbMarket from '../assets/img/6_proyectos_reclutachat.png'
import LogoCbMarket from '../assets/img/cbmarket logo.svg'

const Fifthsection = () => {
    return (
        <div className=''>
            <div>
                <div className='container mt-5'
                    // data-aos="fade-left"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                >
                    <div className='d-flex align-items-center ' id="Proyectos">
                        <div className='SansProLight' style={{ fontSize: '60px' }}>Proyectos</div>
                        <div className='mx-5'>
                            <img src={Arrow} width={'100%'} alt='Flecha' />
                        </div>
                        <div>
                            <img className=' d-none d-lg-block' src={Bubble2} width={'100px'} alt='Burbuja' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <div className='container'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className='d-md-block d-lg-none mt-5 text-center'><img src={LogoReclutachat} style={{ width: '250px' }} alt='Logo Reclutachat' /></div>
                    <div className='mt-lg-5'>
                        <div className='d-flex'>
                            <div className=''>
                                <img src={ImgReclutachat} className='imgProyects' alt='Imagen Reclutachat' />
                            </div>
                            
                            <div className='ms-5 d-none d-lg-block'>
                                <div className='border-bottom'>
                                    <div className='mt-3'>
                                        <img src={LogoReclutachat} style={{ width: '250px' }} alt='Logo Reclutachat' />
                                    </div>
                                    <div className='SansProLight mt-4' style={{ fontSize: '35px' }}>Herramientas <br /> tecnológicas para <br /> <span className='SansProBold'>contrataciones masivas.</span> </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='SansProSemibold'>¿Cómo funciona Reclutachat?</div>
                                    <div className='SansProLight'>
                                        Este es un breve resumen de Reclutachat es un sistema que vive con un <br />
                                        CMS, el cual registra todas las necesidades para nuevas <br />
                                        contrataciones, esta información se lleva a un cuestionario <br />
                                        inteligente el cual se puede usar en sistemas de mensajería como <br />
                                        menssenger, whatsapp, telegram junto a un bot inteligente, de <br />
                                        misma forma se puede utilizar dentro de un sitio web o puede <br />
                                        convertirse en un juego.
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='SansProSemibold'>
                                        Este sistema entrevista candidatos para filtrar a los más aptos. <br />
                                        <span className='SansProLight'>
                                            Una vez que el candidato pasa el filtro es citado de forma automática. El sistema almacena tanto a interesados como <br />contratados, y ayuda a las empresas a generar el onboarding de  <br />las nuevas contrataciones.
                                        </span>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div className='container'
                    // data-aos="fade-left"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                >
                    <div className='d-md-block d-lg-none mt-5 text-center'><img src={LogoTlegal} style={{ width: '250px' }} alt='Logo Reclutachat' /></div>
                    <div className='mt-lg-5'>
                        <div className='d-flex'>
                            <div className=''>
                                <img src={ImgTlegal} className='imgProyects' alt='TLegal' />
                            </div>
                            <div className='ms-5 d-none d-lg-block'>
                                <div className='border-bottom'>
                                    <div className='mt-4'>
                                        <img src={LogoTlegal} style={{ width: '250px' }} alt='Logo tlegal' />
                                    </div>
                                    <div className='SansProLight mt-4' style={{ fontSize: '35px' }}>Legal <span className='SansProBold'>Tech</span> </div>
                                </div>
                                <div className='mt-3'>

                                    <div className='SansProLight'>
                                        Durante la era actual, la mayoría de las empresas están migrando <br />
                                        a <span className='SansProBold'>tenologías enfocadas en las áreas legales de las empresas.</span> <br /><br />
                                        Nuestra experiencia nace por un desarrollo que hicimos para la <br />
                                        creación de una plataforma audaz que ayuda a los clientes a <br />
                                        gestionar todos los contratos de una empresa, firmar de forma <br />
                                        digital, tener testigos, y un gran distema de seguridad para toda la <br />
                                        información.

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className='container'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div className='d-md-block d-lg-none mt-5 text-center'><img src={LogoNetbee} style={{ width: '250px' }} alt='Logo Reclutachat' /></div>
                    <div className='mt-lg-5'>
                        <div className='d-flex'>
                            <div className=''>
                                <img src={ImgNetbee} className='imgProyects' alt='Imagen Netbee' />
                            </div>
                            <div className='ms-5 d-none d-lg-block'>
                                <div className='border-bottom'>
                                    <div className='mt-4'>
                                        <img src={LogoNetbee} style={{ width: '200px' }} alt='Logo Netbee' />
                                    </div>
                                    <div className='SansProLight mt-4' style={{ fontSize: '35px' }}>Trabajo remoto, el primer <br /> <span className='SansProBold'>co-work digital</span> </div>
                                </div>
                                <div className='mt-3'>

                                    <div className='SansProLight'>
                                        La pandemia nos enseñó a trabajar de forma remota, y es gracias a <br />
                                        esta nueva modalidad de trabajo que las empresas buscan <br />
                                        tecnología que les ayude a ser ágiles, y confiar en el trabajo de <br />
                                        cada personal, y buscar la forma de comprobar el cumplimento <br />
                                        de tareas.
                                    </div>
                                    <div className='mt-3 SansProBold'>
                                        Es por esto que hemos ayudado a crear plataformas de gestión <br />
                                        de tareas.
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className='container'
                    // data-aos="fade-left"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                >
                    <div className='d-md-block d-lg-none mt-5 text-center'><img src={LogoCbMarket} style={{ width: '250px' }} alt='Logo Reclutachat' /></div>
                    <div className='mt-lg-5'>
                        <div className='d-flex'>
                            <div className=''>
                                <img src={ImgCbMarket} className='imgProyects' alt='Imagen CbMarket' />
                            </div>
                            <div className='ms-5 d-none d-lg-block'>
                                <div className='border-bottom'>
                                    <div className='mt-4'>
                                        <img src={LogoCbMarket} style={{ width: '200px' }} alt='Logo Market' />
                                    </div>
                                    <div className='SansProLight mt-4' style={{ fontSize: '35px' }}>e-<span className='SansProBold'>commerce</span> </div>
                                </div>
                                <div className='mt-3'>

                                    <div className='SansProLight'>
                                        EL CBD se vuelve legal para venta en US, México y es gracias a esto <br />
                                        que siguiendo las leyes tan precisas y la regularización es que <br />
                                        creamos un sitio para satisfacer las necesidades de los <br />
                                        consumidores y abrir el gran espacio que la marca esperaba.
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Fifthsection