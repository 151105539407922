import Menu from './components/menu';
import Navbar from './components/navbar';
import { GlobalContext } from './context/global_context';
import { GlobalContextProvider } from './context/global_context';
import './styles/estilos.css';
import './styles/estilosMobil.css'
import HomeView from "./views/HomeView";
import NoticePrivacyView from './views/NoticePrivacyView';
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useContext } from 'react';

AOS.init()


const RouterApp = () => {
  let routes = useRoutes([
    { path: "/", element: <HomeView /> },
    { path: "/aviso-de-privacidad", element: <NoticePrivacyView /> },
  ]);
  return routes;
}

function App() {
  // const {GlobalState, setGlobalState} = useContext(GlobalContext)
  return (
    <div className="App">
      <GlobalContextProvider>
        <Router>
          <Navbar />
          <RouterApp />
        </Router>
      </GlobalContextProvider>
    </div >
  );
}

export default App;